<template>
    <div class="Wager">
        <div class="card card--form">
            <!-- <form @submit="submit"> -->
            <div v-if="!success">
                <h3>{{ edit ? 'Manage Wager' : 'New Wager' }}</h3>
                <input type="text" placeholder="Name" v-model="name" :disabled="edit">
                <input type="text" placeholder="Variant" v-model="variant" :disabled="edit">
                <input type="text" placeholder="Course" v-model="course">
                <p class="footnote">A name / variant combo is how you describe the wager to your members. Check the help section for more info on how to use these fields.</p>
                <h3>Start Time</h3>
                <input type="date" v-model="date">
                <div class="dropdown">
                    <select v-model="time">
                        <option value="00:00">00:00</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                    </select>
                    <Chevron theme="grey" />
                </div>
                <h3>Tags</h3>
                <input type="text" placeholder="TAG" v-model="tag" @keyup="addTag" ref="tag">
                <div class="tag" v-for="(t, index) in tags" :key="t">
                    <input type="text" disabled :value="t">
                    <Close @onClick="spliceTag(index)" />
                </div>
                <p class="footnote">Tags are a way to categorize your wagers. Each unique tag will appear as a filter option to help your members navigate your book. Press "Enter" on your device to add a tag.</p>
                <h3>Type</h3>
                <div class="dropdown">
                    <select v-model="type" :disabled="edit">
                        <option value="overUnder">Over / Under</option>
                        <option value="twoOutcome">Two Outcome</option>
                        <option value="threeOutcome">Three Outcome</option>
                        <option value="fourOutcome">Four Outcome</option>
                        <option value="future">Future</option>
                    </select>
                    <Chevron theme="grey" />
                </div>
                <p class="footnote">Over / Under wagers are limited to a single line revolving around a total. Two, Three, and Four Outcome wagers are limited to the number of lines as indicated. Futures allow up to 50 lines to be offered in a single wager. Check the help section for more info on wager types.</p>
                <h3>Line(s)</h3>
                <div v-if="type === 'overUnder'">
                    <input type="number" placeholder="TOTAL" v-model="total">
                </div>
                <div v-if="type === 'overUnder' || type === 'twoOutcome' || type === 'threeOutcome' || type === 'fourOutcome'">
                    <div class="row">
                        <input type="text" placeholder="LINE" v-model="lines[0]" :disabled="type === 'overUnder' || edit">
                        <input type="number" placeholder="0.00" step="0.01" min="1" v-model="odds[0]">
                    </div>
                    <div class="row">
                        <input type="text" placeholder="LINE" v-model="lines[1]" :disabled="type === 'overUnder' || edit">
                        <input type="number" placeholder="0.00" step="0.01" min="1" v-model="odds[1]">
                    </div>
                </div>
                <div v-if="type === 'threeOutcome' || type === 'fourOutcome'">
                    <div class="row">
                        <input type="text" placeholder="LINE" v-model="lines[2]" :disabled="edit">
                        <input type="number" placeholder="0.00" step="0.01" min="1" v-model="odds[2]">
                    </div>
                </div>
                <div v-if="type === 'fourOutcome'">
                    <div class="row">
                        <input type="text" placeholder="LINE" v-model="lines[3]" :disabled="edit">
                        <input type="number" placeholder="0.00" step="0.01" min="1" v-model="odds[3]">
                    </div>
                </div>
                <div v-if="type === 'future'">
                    <div class="row" v-for="index in (lines.length + 1)" :key="index - 1">
                        <input type="text" placeholder="LINE" v-model="lines[index - 1]" :disabled="edit">
                        <input type="number" placeholder="0.00" step="0.01" min="1" v-model="odds[index - 1]">
                        <Close v-if="(index - 1) > 0 && lines[index - 1]" @onClick="spliceLine(index - 1)" />
                    </div>
                </div>
                <h3>Max Bet</h3>
                <input type="number" placeholder="AMOUNT" v-model="max" step="1" min="1" max="100">
                <Button :disabled="!isReady" arrow @onClick="submit">{{ edit ? 'Update Wager' : 'Create Wager' }}</Button>
                <Button v-if="edit && wager.status === 'open'" @onClick="suspendWager" theme="grey">Suspend Wager</Button>
                <Button v-if="edit && wager.status === 'suspended'" @onClick="reopenWager" theme="grey">Re-Open Wager</Button>
                <Button v-if="edit && !wager.bets" @onClick="isDeleting = true" theme="red">Delete Wager</Button>
                <Confirm v-if="isDeleting" okay="Yes" @okay="deleteWager()" @cancel="isDeleting = false">Are you sure you want to delete this wager?</Confirm>
            <!-- </form> -->
            </div>
            <p class="error" v-if="error">{{ error }}</p>
            <div class="success" v-if="success">
                <h3>Success!</h3>
                <p>Your {{ edit ? '' : 'new' }} wager has been {{ edit ? 'updated' : 'created' }}. Press the button below to manage your wagers.</p>
                <router-link :to="{ name: 'Wagers', params: { book_id: $route.params.book_id } }">
                    <Button arrow>Manage</Button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';
    import Close from '@/components/Close.vue';
    import Chevron from '@/components/vectors/Chevron.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { createWager, suspendWager, reopenWager, deleteWager } from '@/firebase/wagers.js';

    // Misc.
    import { getDate, getTime } from '@/utils.js';

    export default {
        name: 'CreateWager',
        components: {
            Button,
            Close,
            Chevron,
            Confirm,
        },
        props: {
            edit: Boolean,
            wager: { type: Object, required: false },
        },
        data() {
            return {
                name: '',
                variant: '',
                course: '',
                date: '',
                time: '00:00',
                tag: '',
                tags: [],
                type: 'overUnder',
                total: '',
                odds: [],
                lines: [],
                max: '',
                error: null,
                success: false,
                isDeleting: false,
            }
        },
        computed: {
            isReady() {

                const name    = this.name.trim();
                const variant = this.variant.trim();
                const date    = this.date;
                const time    = this.time;
                const total   = parseFloat(this.total);
                const lines   = this.lines.map(line => line.trim());
                const odds    = this.odds.map(odd => parseFloat(odd));
                const max     = parseFloat(this.max);

                switch (this.type) {
                    default:
                    case 'overUnder':
                        return (name && variant && date && time && total && lines[0] && lines[1] && odds[0] && odds[1] && max);
                    case 'twoOutcome':
                        return (name && variant && date && time && lines[0] && lines[1] && odds[0] && odds[1] && max);
                    case 'threeOutcome':
                        return (name && variant && date && time && lines[0] && lines[1] && lines[2] && odds[0] && odds[1] && odds[2] && max);
                    case 'fourOutcome':
                        return (name && variant && date && time && lines[0] && lines[1] && lines[2] && lines[3] && odds[0] && odds[1] && odds[2] && odds[3] && max);
                    case 'future':
                        return (name && variant && date && time && lines[0] && lines[1] && odds[0] && odds[1] && max);
                }

            }
        },
        created() {

            const { wager } = this.$props;

            if (wager) {
                this.name    = wager.name;
                this.variant = wager.variant;
                this.course  = wager.course || '';
                this.date    = getDate(wager.startTime);
                this.time    = getTime(wager.startTime);
                this.tags    = wager.tags || [];
                this.type    = wager.type;
                this.lines   = wager.lines;
                this.odds    = wager.odds;
                this.max     = wager.max;

                if (wager.type === 'overUnder') this.total = wager.total;

            }

        },
        methods: {
            addTag(e) {
                if (e.key === 'Enter' || e.keyCode === 13) {

                    const tag = this.tag.trim().replace('/', '-').toUpperCase();

                    if (tag !== '' && !this.tags.includes(tag)) {
                        this.tags.push(tag);
                        this.tag = '';
                    }

                }
            },
            submit() {

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const name    = this.name.trim();
                const variant = this.variant.trim();
                const date    = this.date;
                const time    = this.time;
                const total   = parseFloat(this.total);
                const lines   = this.lines.map(line => line.trim().toUpperCase());
                const odds    = this.odds.map(odd => parseFloat(odd));
                const max     = parseFloat(this.max);

                if (!name) {
                    this.error = 'Name is required.';
                    return;
                }

                if (!variant) {
                    this.error = 'Variant is required.';
                    return;
                }

                if (!date) {
                    this.error = 'Start date is required.';
                    return;
                }

                if (!time) {
                    this.error = 'Start time is required.';
                    return;
                }

                if (!max || max < 1) {
                    this.error = 'Max bet is required and must be at least 1.';
                    return;
                }

                switch (this.type) {
                    default:
                    case 'overUnder': {

                        if (!total || total < 0.5) {
                            this.error = 'Total is required and must be at least 0.5.';
                            return;
                        }

                        if (!lines[0] || !lines[1] || !odds[0] || !odds[1] || odds[0] <= 1 || odds[1] <= 1) {
                            this.error = 'There is a problem with the lines or odds.';
                            return;
                        }

                        this.save('overUnder');

                    }
                        break;
                    case 'twoOutcome': {

                        if (!lines[0] || !lines[1] || !odds[0] || !odds[1] || odds[0] <= 1 || odds[1] <= 1) {
                            this.error = 'There is a problem with the lines or odds.';
                            return;
                        }

                        this.save('twoOutcome');

                    }
                        break;
                    case 'threeOutcome': {

                        if (!lines[0] || !lines[1] || !lines[2] || !odds[0] || !odds[1] || !odds[2] || odds[0] <= 1 || odds[1] <= 1 || odds[2] <= 1) {
                            this.error = 'There is a problem with the lines or odds.';
                            return;
                        }

                        this.save('threeOutcome');

                    }
                        break;
                    case 'fourOutcome': {

                        if (!lines[0] || !lines[1] || !lines[2] || !lines[3] || !odds[0] || !odds[1] || !odds[2] || !odds[3] || odds[0] <= 1 || odds[1] <= 1 || odds[2] <= 1 || odds[3] <= 1) {
                            this.error = 'There is a problem with the lines or odds.';
                            return;
                        }

                        this.save('fourOutcome');

                    }
                        break;
                    case 'future': {

                        for (let line of lines) {
                            if (!line) {
                                this.error = 'There is a problem with the lines or odds.';
                                return;
                            }
                        }

                        for (let odd of odds) {
                            if (!odd || odd <= 1) {
                                this.error = 'There is a problem with the lines or odds.';
                                return;
                            }
                        }

                        this.save('future');

                    }
                        break;

                }

            },
            spliceTag(index) {
                this.tags.splice(index, 1);
            },
            spliceLine(index) {
                this.lines.splice(index, 1);
                this.odds.splice(index, 1);
            },
            save(type) {

                const name    = this.name.trim().replace('/', '-');
                const variant = this.variant.trim().replace('/', '-');
                const course  = this.course.trim().replace('/', '-');
                const date    = this.date;
                const time    = this.time;
                const tags    = this.tags;
                const total   = parseFloat(this.total) || 0;
                const lines   = this.lines.map(line => line.trim().replace('/', '-').toUpperCase());
                const odds    = this.odds.map(odd => parseFloat(odd));
                const max     = parseFloat(this.max);

                createWager({
                    type,
                    name,
                    variant,
                    course,
                    date: `${ date }T${ time }`,
                    tags,
                    total,
                    lines,
                    odds,
                    max,
                }, this.$route.params.book_id, this.$props.edit ? this.$route.params.wager_id : null).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                }).catch(error => {
                    this.error = error;
                    this.$store.dispatch('updateLoading', -1);
                });

            },
            suspendWager() {
                this.$store.dispatch('updateLoading', 1);
                suspendWager(this.$route.params.wager_id).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                });
            },
            reopenWager() {
                this.$store.dispatch('updateLoading', 1);
                reopenWager(this.$route.params.wager_id).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                });
            },
            deleteWager() {
                this.$store.dispatch('updateLoading', 1);
                deleteWager(this.$route.params.book_id, this.$route.params.wager_id).then(() => {
                    this.$store.dispatch('updateLoading', -1);
                    this.$router.replace({ name: 'Wagers', params: { book_id: this.$route.params.book_id } });
                });
            }
        },
        watch: {
            total(to) {
                this.lines[0] = `OVER ${ parseFloat(to).toFixed(1) }`;
                this.lines[1] = `UNDER ${ parseFloat(to).toFixed(1) }`;
            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .card--form {
        margin: size(Medium) auto;
    }

    .card--form input,
    .card--form p,
    .card--form .Button {
        margin-top: size(Small);
    }

    .card--form .success .Button {
        margin-top: size(Large);
    }

    .card--form .tag input {
        margin: 0;
    }

    .card--form h3 {
        margin-top: size(Medium);
    }

    .card--form h3:first-of-type {
        margin-top: 0;
    }

    .dropdown {
        position: relative;
        margin-top: size(Small);
    }

    .Chevron {

        position: absolute;
        top: 50%;
        right: size(Medium);

        transform: translateY(-50%);

        pointer-events: none;

    }

    .row {

        position: relative;
        display: flex;

        margin-top: size(Small);

    }

    .row input {
        text-transform: uppercase;
        margin-top: 0;
    }

    .row input:first-of-type {
        flex-grow: 1;
    }

    .row input:last-of-type {

        width: 60px;
        padding: 0 size(Small);
        margin-left: size(Small);

        text-align: center;

    }

    input[type=text]:disabled {

        // opacity: 1;

        // background: color(Emperor);
        // color: color(White);

    }

    .tag {
        position: relative;
        margin-top: size(Small);
    }

    .Close {

        position: absolute;
        top: 50%;
        left: calc(100% + #{size(Micro)});

        transform: translateY(-50%);

    }

</style>
