<template>
    <div class="BookSelector">
        <div class="bar" @click="toggle">
            <Cell class="book" icon="book" theme="white" />
            <Chevron class="chevron" :class="{ flip: isOpen }" theme="white" />
            <h4>{{ selectedName }}</h4>
        </div>
        <transition
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <div class="dropdown" v-if="isOpen">
                <router-link class="row" v-for="book in books" :key="book[0]" :to="{ name: $route.name, params: { book_id: book[0] } }" @click.native="toggle">
                    <h4>{{ book[1].name }}</h4>
                    <h4 class="bet-count" v-if="showWagerCount">{{ wagerCounts[book[0]] || 0 }}</h4>
                </router-link>
            </div>
        </transition>
    </div>
</template>

<script>

    // Modules.
    import gsap from 'gsap';

    // Components.
    import Cell from '@/components/vectors/Cell.vue';
    import Chevron from '@/components/vectors/Chevron.vue';

    // Firebase.
    import { watchBook } from '@/firebase/books.js';
    import { getWager } from '@/firebase/wagers.js';

    export default {
        name: 'BookSelector',
        components: {
            Cell,
            Chevron,
        },
        props: {
            selectedName: String,
            books: Array,
            showWagerCount: Boolean,
        },
        data() {
            return {
                isOpen: false,
                wagerCounts: {},
            }
        },
        created() {
            if (this.$props.showWagerCount) {
                for (const book of this.books) {
                    watchBook(book[0]).on('value', snapshot => {

                        const val = snapshot.val();

                        if (val.wagers) {

                            let openWagerCount = 0;

                            for (const wager of Object.keys(val.wagers)) {
                                getWager(wager).then(snapshot => {
                                    if (snapshot.val().startTime > Date.now()) openWagerCount++;
                                    this.wagerCounts[book[0]] = openWagerCount;
                                });
                            }
                        }

                    });
                }
            }
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
            change(id) {
                this.$emit('change', id);
                this.toggle();
            },
            enter(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.fromTo(el, { y: -height }, { y: 0, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            },
            leave(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.to(el, { y: -height, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .BookSelector {

        position: fixed;
        top: 50px;
        left: 0;
        z-index: 25;

        width: 100%;

    }

    .bar {

        position: relative;
        z-index: 10;

        height: size(Large);
        padding: 0 size(Medium);

        background-color: color(OldGold);

        display: flex;
        align-items: center;
        justify-content: space-between;

        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .bar h4 {

        color: color(White);
        // text-transform: uppercase;
        text-align: center;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

    }

    .row {

        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 50px;

        background-color: color(WildSand);
        border-bottom: 1px solid color(Emperor, 0.15);

        color: color(Emperor);

    }

    .row:last-of-type {
        border-bottom: none;
    }

    .dropdown {
        box-shadow: 0 0 15px color(Black, 0.1);
    }

    .bet-count {

        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px;
        height: 24px;

        border-radius: 50%;
        background-color: color(Emperor);

        color: color(White);

        position: absolute;
        top: 50%;
        right: size(Medium);

        transform: translateY(-50%);

    }

</style>

<style lang="scss">

    .BookSelector .book svg {
        width: 20px;
        height: 20px;
    }

    .BookSelector .chevron {
        width: 14px;
        height: 14px;
    }

    .BookSelector .chevron.flip {
        transform: scaleY(-1);
    }

</style>
